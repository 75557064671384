import React, {memo, useCallback} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useSearchParams} from 'react-router-dom';
import {FreeMode, Keyboard} from 'swiper/modules';

import {AppButton} from '../../UI';

import {getNextSevenDaysUnixTimestamps} from '../../../Utils/GetNextSevenDaysFilters';

import './index.scss';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {useScreenSize} from '../../../Hooks';

const LiveCalendarFilters = () => {
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const getDayParam = getParam.get('day');
  const getRegionParam = getParam.get('region');

  const onClickItem = useCallback(
    item => {
      const dayParamParts = getDayParam?.split('_');

      if (!!getRegionParam) {
        getParam.delete('region');
      }
      if (
        !!getDayParam &&
        +dayParamParts?.[0] === item?.startOfDay &&
        +dayParamParts?.[1] === item?.endOfDay
      ) {
        getParam.delete('day');
      } else {
        getParam.set('day', `${item?.startOfDay}_${item?.endOfDay}`);
      }
      setGetParam(getParam);
    },
    [getDayParam, getParam, getRegionParam, setGetParam],
  );

  return (
    <div
      className={`liveCalendarFilters pr-sm ${
        width > BREAKPOINT_XS ? 'pl-xxxl flex justify-center align-center' : ''
      }`}>
      <Swiper
        freeMode
        keyboard
        mousewheel
        spaceBetween={5}
        slidesPerView="auto"
        modules={[Keyboard, FreeMode]}
        wrapperClass={width > BREAKPOINT_XS ? ' mx-auto pa-xxs' : 'pa-xxs '}
        className={`liveCalendarFiltersSwiper wrapperBorders ${
          width > BREAKPOINT_XS ? 'desktop full-width' : 'mobile mt-sm '
        }`}>
        {(getNextSevenDaysUnixTimestamps(true) || [])?.map((item, index) => (
          <SwiperSlide key={index}>
            <AppButton
              type={19}
              height={30}
              active={
                +item?.startOfDay === +getDayParam?.split?.('_')?.[0] &&
                +item?.endOfDay === +getDayParam?.split?.('_')?.[1]
              }
              className="row items-center justify-center matchItem"
              onClick={() => onClickItem(item)}
              title={
                <span className={`font-12 bold-600`}>
                  {`${item?.dayName} ${item?.day}`}
                </span>
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default memo(LiveCalendarFilters);

import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useScreenSize, useSocket} from '../../../../Hooks';

import RegionItem from './RegionItem';

import {
  GET_All_REGIONS,
  GET_USER_DATA_PERSONALIZATION,
  SET_USER_DATA_PERSONALIZATION,
} from '../../../../Constants/Socket';

import {
  getSid,
  getWssSocket,
  getIsLoggedIn,
} from '../../../../Redux/AppSlice/AppSelectors';

import RegionSkeletons from '../../../UI/Skeletons/RegionSkeletons';
import {AppButton, AppInput} from '../../../UI';
import {useTranslation} from 'react-i18next';

import {ReactComponent as LeftIcon} from '../../../../Assets/Icons/Globals/leftTriangle.svg';

import './SideBarRegions.scss';
import {setShowSideBar} from '../../../../Redux/SportSlice';
import {
  getShowSideBar,
  getBoostedGameIds,
  getFavouriteCompetitionsObject,
} from '../../../../Redux/SportSlice/SportSelectors';
import {BREAKPOINT_XS} from '../../../../Constants/Globals';
import {useLocation, useSearchParams} from 'react-router-dom';

import {ReactComponent as SearchIcon} from '../../../../Assets/Icons/Globals/search.svg';
import {
  MATCH_FILTERS,
  FILTERS_BOOSTED_ODDS_NAME,
  FILTERS_FAVOURITE_COMPETITIONS_NAME,
} from '../../../../Constants/MatchFilters';

const SideBarRegions = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );

  const getDayParam = getParam.get('day');
  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const regionParam = useMemo(() => getParam.get('region'), [getParam]);
  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);

  const {getLeagues, getInitialGames} = useSocket();

  const sid = useSelector(getSid);
  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const showSideBar = useSelector(getShowSideBar);
  const boostedIds = useSelector(getBoostedGameIds);

  const favouriteCompetitionsObject = useSelector(
    getFavouriteCompetitionsObject,
  );

  const [regions, setRegions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedFilteredIndex, setSelectedFilteredIndex] = useState(null);
  const [isRegionsLoading, setIsRegionsLoading] = useState(false);

  const filteredRegions = useMemo(
    () =>
      inputValue?.length > 0
        ? regions?.filter(item =>
            item?.alias
              ?.toLowerCase()
              ?.includes(inputValue?.trim?.()?.toLowerCase()),
          )
        : [],
    [inputValue, regions],
  );

  const selectedCompetitionsIdsLength = useMemo(() => {
    const splitArray = regionParam?.split?.('-');
    const competitions =
      splitArray?.map(item => item?.split?.('_')?.slice?.(1)) || [];
    return (competitions || [])?.flat?.(Infinity)?.length;
  }, [regionParam]);

  const resetClickHandler = useCallback(() => {
    if (!!inputValue) {
      setInputValue('');
    }
    if (!!regionParam) {
      getParam.delete('region');
      setGetParam(getParam);
    }
    if (!!getDayParam) {
      getParam.delete('day');
      setGetParam(getParam);
    }
  }, [getDayParam, getParam, inputValue, regionParam, setGetParam]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);

      switch (data?.rid) {
        case GET_All_REGIONS:
          setSelectedIndex(0);
          setRegions(
            Object.values(data?.data?.data?.region || {})?.sort(
              (a, b) => a?.order - b?.order,
            ),
          );
          setIsRegionsLoading(false);
          break;
        case SET_USER_DATA_PERSONALIZATION:
          if (gameFilterParam === FILTERS_FAVOURITE_COMPETITIONS_NAME) {
            const favouriteCompetitionsArray = Object?.keys(
              favouriteCompetitionsObject || {},
            )?.map(item => +item);

            getInitialGames({
              withoutLimit: true,
              activeSportAlias: sportParam,
              competitionsIdsArray: favouriteCompetitionsArray,
            });
          }
          break;
        default:
          break;
      }
    },
    [favouriteCompetitionsObject, gameFilterParam, getInitialGames, sportParam],
  );

  useEffect(() => {
    if (!!sid) {
      const favouriteCompetitionsArray = Object?.keys(
        favouriteCompetitionsObject || {},
      )?.map(item => +item);

      if (!showSideBar) {
        getLeagues({
          gameFilters:
            gameFilterParam === FILTERS_FAVOURITE_COMPETITIONS_NAME
              ? {
                  competition: {
                    id: {
                      '@in': favouriteCompetitionsArray,
                    },
                  },
                }
              : gameFilterParam === FILTERS_BOOSTED_ODDS_NAME &&
                boostedIds !== null
              ? {
                  game: {
                    id: {
                      '@in': Object.keys(boostedIds || {})?.map(item => +item),
                    },
                  },
                }
              : MATCH_FILTERS?.[gameFilterParam]?.status,
          activeSportAlias: sportParam,
        });
      }
    }
  }, [
    sid,
    sportParam,
    boostedIds,
    gameFilterParam,
    favouriteCompetitionsObject,
  ]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    if (showSideBar && width < BREAKPOINT_XS) {
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }, [showSideBar, width]);

  return (
    <div className="sideBarRegionsContainerWrapper">
      {showSideBar && width < BREAKPOINT_XS && (
        <div
          className={`absolute-full sideBarOverlay`}
          onClick={() => dispatch(setShowSideBar(false))}
        />
      )}
      <div
        className={`${
          (width < 1360 && width > BREAKPOINT_XS && !!sportGameIdParam) ||
          location.pathname.includes('jackpot')
            ? 'hidden'
            : ''
        } ${
          width > BREAKPOINT_XS ? '' : 'fixed full-height scroll-y scroll-5'
        } ${
          width < BREAKPOINT_XS
            ? showSideBar
              ? 'z-max slideRight fixed'
              : 'slideLeft'
            : ''
        } sideBarRegionsContainer`}
        style={{
          width: 310,
          backgroundColor: '#194478',
          height: width < BREAKPOINT_XS ? 'calc(100dvh - 46px)' : '100%',
        }}>
        {isRegionsLoading ? (
          <RegionSkeletons />
        ) : (
          <div className="flex column">
            <div className="row items-center px-md pt-sm gap-5">
              <AppInput
                search
                type={4}
                height={40}
                inputValue={inputValue}
                onChange={text => {
                  if (
                    typeof selectedIndex === 'number' ||
                    typeof selectedFilteredIndex === 'number'
                  ) {
                    setSelectedIndex(null);
                    setSelectedFilteredIndex(null);
                  }
                  setInputValue(text);
                }}
                placeholder={t('searchAnyLeague')}
                withoutFullWidth={width < BREAKPOINT_XS}
              />
              <AppButton
                type={16}
                width={80}
                height={40}
                className={`resetButton`}
                onClick={resetClickHandler}
                disabled={!regionParam && !inputValue && !getDayParam}
                title={
                  <span className="bold-600">{`${
                    selectedCompetitionsIdsLength > 0
                      ? `(${selectedCompetitionsIdsLength})`
                      : ''
                  } ${t('resetLower')}`}</span>
                }
              />
              {width < BREAKPOINT_XS && (
                <div
                  onClick={() => dispatch(setShowSideBar(!showSideBar))}
                  className="flex whiteBackground rounded-borders items-center justify-center"
                  style={{width: 40, height: 40}}>
                  <LeftIcon />
                </div>
              )}
            </div>
            {filteredRegions?.length > 0 && (
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                }}>
                <div style={{}} className="">
                  <div
                    className="flex items-center  gap-7 mb-md px-md"
                    style={{marginBottom: 10}}>
                    <SearchIcon width={10} height={10} fill="var(--white)" />
                    <span className="whiteText bold-600 font-11">{`${
                      filteredRegions?.length
                    }  ${t('resultsFound')}`}</span>
                  </div>
                  <div
                  // style={{maxHeight: 150}}
                  // className="scroll-auto-y scroll-3"
                  >
                    {filteredRegions?.map((regionItem, index) => (
                      <RegionItem
                        index={index}
                        isFilteredItem
                        regionItem={regionItem}
                        key={`filteredRegions_${index}`}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        isExpanded={index === selectedFilteredIndex}
                        selectedFilteredIndex={selectedFilteredIndex}
                        setSelectedFilteredIndex={setSelectedFilteredIndex}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {['_', ...regions]?.map((regionItem, index) => {
              return (
                <RegionItem
                  key={index}
                  index={index}
                  regionItem={regionItem}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  isExpanded={index === selectedIndex}
                  selectedFilteredIndex={selectedFilteredIndex}
                  setSelectedFilteredIndex={setSelectedFilteredIndex}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(SideBarRegions);

import {
  CATEGORY_DICE_ID,
  CATEGORY_CRASH_ID,
  CATEGORY_BLAST_ID,
  CATEGORY_AVIATOR_ID,
  CATEGORY_BIG_HILO_ID,
  CATEGORY_BOOSTED_RTP_GAMES_ID,
  CATEGORY_KENO_ID,
  CATEGORY_MINES_ID,
} from './CasinoMenuFilter';

export const CASINO_FIXED_GAMES_VALUES = [
  {
    isNew: true,
    iconSize: 12,
    icon: 'aviator',
    title: 'aviator',
    name: 'aviator',
    extearnal_game_id: CATEGORY_AVIATOR_ID,
  },

  {
    icon: 'mines',
    iconSize: 12,
    name: 'mines',
    title: 'mines',
    extearnal_game_id: CATEGORY_MINES_ID,
  },
  {
    icon: 'dice',
    iconSize: 12,
    title: 'dice',
    name: 'dice',
    extearnal_game_id: CATEGORY_DICE_ID,
  },
  {
    icon: 'blast',
    iconSize: 12,
    name: 'blast',
    title: 'blast',
    extearnal_game_id: CATEGORY_BLAST_ID,
  },
  {
    icon: 'keno',
    iconSize: 12,
    name: 'keno',
    title: 'keno',
    extearnal_game_id: CATEGORY_KENO_ID,
  },
  // {
  //   icon: 'crash',
  //   iconSize: 12,
  //   title: 'crash',
  //   name: 'crash',
  //   extearnal_game_id: CATEGORY_CRASH_ID,
  // },
  // {
  //   iconSize: 12,
  //   icon: 'BoostedRTP',
  //   title: 'BoostedRTP',
  //   name: 'BoostedRTP',
  //   category: 'boostedRTP',
  //   category_id: CATEGORY_BOOSTED_RTP_GAMES_ID,
  //   // extearnal_game_id: CATEGORY_BIG_HILO_ID,
  // },
  // {
  //   iconSize: 12,
  //   icon: 'bigHiLo',
  //   title: 'bigHilo',
  //   name: 'bigHilo',
  //   extearnal_game_id: CATEGORY_BIG_HILO_ID,
  // },
];

import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {useSocket} from '../../../../Hooks';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {setIsBetSlipDataLoading} from '../../../../Redux/BettingSlice';
import {getIsBetSlipDataLoading} from '../../../../Redux/BettingSlice/BettingSelectors';

import {GET_BOOKED_BETS} from '../../../../Constants/Socket';

import {AppButton, AppInput} from '../../../UI';

const LoadEventsContainer = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {getBookedBets, subscribeToEvent} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const isBetSlipDataLoading = useSelector(getIsBetSlipDataLoading);

  const inputRef = useRef(null);

  const [code, setCode] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);

  const loadPressHandler = useCallback(() => {
    dispatch(setIsBetSlipDataLoading(true));
    getBookedBets({booking_id: +code});
  }, [code, dispatch, getBookedBets]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);

      switch (data?.rid) {
        case GET_BOOKED_BETS:
          if (data?.data?.result === 0 && !!data?.data?.details?.id) {
            data?.data?.details?.selections?.forEach(item => {
              subscribeToEvent({
                gameId: item?.match_id,
                marketId: item?.market_id,
                eventId: item?.selection_id,
              });
            });
            setCode('');
            // dispatch(setCallToActionData({eventId, price, deleteItem: false})); // TODO add price after BC fix
          } else {
            setErrorMsg(t('invalidBookingId'));
            inputRef?.current?.blur?.();
            dispatch(setIsBetSlipDataLoading(false));
          }
          break;
        default:
          break;
      }
    },
    [dispatch, subscribeToEvent, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="row gap-10">
      <AppInput
        type={8}
        height={30}
        error={errorMsg}
        inputValue={code}
        onChange={setCode}
        className="font-10"
        inputRef={inputRef}
        setErrorMsg={setErrorMsg}
        placeholder={t('bettingCodePlaceholder')}></AppInput>
      <AppButton
        type={1}
        width={58}
        height={30}
        title={t('load')}
        onClick={loadPressHandler}
        titleClassName="font-12 bold-600"
        className="flex items-center justify-center"
        disabled={!code?.trim() || isBetSlipDataLoading || !!errorMsg}
      />
    </div>
  );
};

export default memo(LoadEventsContainer);

import React, {memo, useMemo} from 'react';
import './index.scss';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {setAuthModal} from '../../../../../Redux/AppSlice';

import {
  PHONE_NUMBER_PREFIX,
  PHONE_NUMBER_PLACEHOLDER,
} from '../../../../../Constants/Globals';

import {AppButton, AppInput, AppLanguageChooser} from '../../../../UI';

import logo from '../../../../../Assets/Icons/Globals/PigabetLogo.svg';
import leGrandeCasino from '../../../../../Assets/Layout/leGrandeCasino.png';
import palmBeachCasino from '../../../../../Assets/Layout/palmBeachCasino.png';
import LasVegasCasino from '../../../../../Assets/Layout/LasVegasLogo.png';

const MainInfo = ({
  phone,
  errorMsg,
  setPhone,
  password,
  setErrorMsg,
  setPassword,
  setCurrentTab,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const phoneErrorMsg = useMemo(
    () =>
      errorMsg ||
      (phone?.trim?.() && phone?.trim?.()?.length !== 9
        ? t('phoneErrorMsg')
        : ''),
    [errorMsg, phone, t],
  );

  const passwordErrorMsg = useMemo(
    () =>
      password?.trim?.() && password?.trim?.()?.length < 4
        ? t('passwordErrorMsg')
        : '',
    [password, t],
  );

  const isContinueDisabled = useMemo(
    () =>
      !!(
        !password?.trim?.() ||
        !phone?.trim?.() ||
        !!passwordErrorMsg ||
        !!phoneErrorMsg
      ),
    [password, passwordErrorMsg, phone, phoneErrorMsg],
  );

  return (
    <div className="mainInfoWrapper container columnCenter gap-10 full-width">
      <img src={logo} alt="logo" className="logo" />
      <span className="registerAnAccountText">{t('registerAnAccount')}</span>
      <div className="rowCenter stepsContainer gap-10">
        <div className="stepBlock_active" />
        <div className="stepBlock" />
      </div>
      <div className="innerBlock column">
        <div className="column">
          <div className="flex row gap-5">
            <div className="codeContainer column items-center justify-center px-xl">
              <span className="blueText font-10 bold-500">
                {t('phoneCode')}
              </span>
              <span className="blueText text-subtitle2 bold-700">
                {PHONE_NUMBER_PREFIX}
              </span>
            </div>
            <AppInput
              maxLength={9}
              inputValue={phone}
              inputType="number"
              onChange={setPhone}
              error={phoneErrorMsg}
              setErrorMsg={setErrorMsg}
              placeholder={PHONE_NUMBER_PLACEHOLDER}
            />
          </div>
          <div className="passwordContainer">
            <AppInput
              password
              inputValue={password}
              onChange={setPassword}
              error={passwordErrorMsg}
              placeholder={t('passwordPlaceholder')}
            />
          </div>

          <div className="continueContainer columnCenter">
            <AppButton
              type={3}
              title={t('continue')}
              disabled={isContinueDisabled}
              onClick={() => setCurrentTab(1)}
            />
          </div>

          <div className="columnCenter">
            <p className="alreadyHaveAnAccount">{t('alreadyHaveAnAccount')}</p>
            <AppButton
              type={1}
              title={t('signIn')}
              onClick={() => dispatch(setAuthModal({tabIndex: 0}))}
            />
          </div>

          <div className="columnCenter bottomContainer">
            <div className="languageContainerWrapper">
              <AppLanguageChooser />
            </div>
            <span className="affiliatesText">{t('affiliates')}</span>
            <div className="rowCenterBetween affiliatesImageContainer gap-20 pt-xl">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/palmbeachcasinotz?igsh=cjJtZzNpcHdkNGp3">
                <img height={35} src={palmBeachCasino} alt="palmBeachCasino" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://lasvegas.co.tz/en/">
                <img height={30} src={LasVegasCasino} alt="LasVegasCasino" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.legrandetz.com">
                <img height={35} src={leGrandeCasino} alt="leGrandeCasino" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MainInfo);

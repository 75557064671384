import React, {memo, useCallback, useMemo} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {useScreenSize} from '../../../Hooks';

import {
  setAuthModal,
  setShowLiveChat,
  setShowMobileBetSlip,
} from '../../../Redux/AppSlice';
import {
  getIsLoggedIn,
  getBetSlipCount,
  getShowLiveChat,
  getShowMobileBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';

import {
  BREAKPOINT_XS,
  MOBILE_BOTTOM_TABS_CASINO,
  MOBILE_BOTTOM_TABS_SPORTS,
} from '../../../Constants/Globals';
import {FILTERS_TODAY_NAME} from '../../../Constants/MatchFilters';

import {ReactComponent as PokerIcon} from '../../../Assets/Icons/BottomTabs/poker.svg';
import {ReactComponent as SlotsIcon} from '../../../Assets/Icons/BottomTabs/slots.svg';
import {ReactComponent as BetSlipIcon} from '../../../Assets/Icons/Globals/betslip.svg';
import {ReactComponent as CasinoIcon} from '../../../Assets/Icons/BottomTabs/casino.svg';
import {ReactComponent as ExploreIcon} from '../../../Assets/Icons/BottomTabs/sports.svg';
import {ReactComponent as JackpotIcon} from '../../../Assets/Icons/BottomTabs/jackpot.svg';
import {ReactComponent as LiveChatIcon} from '../../../Assets/Icons/BottomTabs/livechat.svg';
import {ReactComponent as LiveCasinoIcon} from '../../../Assets/Icons/BottomTabs/LiveCasino.svg';

import LiveChat from '../../LiveChat';

const MobileBottomTab = () => {
  const params = useParams();
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {width} = useScreenSize();
  const dispatch = useDispatch();
  const [getParam, setGetParam] = useSearchParams();

  const casinoGameIdParam = useMemo(
    () => getParam.get('casinoGameId'),
    [getParam],
  );

  const isLoggedIn = useSelector(getIsLoggedIn);
  const showLiveChat = useSelector(getShowLiveChat);
  const betSlipCount = useSelector(getBetSlipCount);
  const showMobileBetSlip = useSelector(getShowMobileBetSlip);

  const tabPressHandler = useCallback(
    item => {
      // if (location?.pathname === item?.path) {
      //   return;
      // }
      dispatch(setShowLiveChat(false));
      dispatch(setShowMobileBetSlip(false));
      if (item?.routePath) {
        if (
          item?.title === 'sports' &&
          getParam.get('gameFilter') === FILTERS_TODAY_NAME
        ) {
          return;
        }

        navigate(item?.routePath);
      } else if (item?.path) {
        navigate(item?.path);
      } else {
        if (item?.action === 'betSlip') {
          dispatch(setShowMobileBetSlip(!showMobileBetSlip));
        } else if (item?.action === 'liveChat') {
          dispatch(setShowLiveChat(!showLiveChat));
        }
      }
      if (item?.action !== 'liveChat' && item?.action !== 'betSlip') {
        window.scrollTo(0, 0);
      }
    },
    [dispatch, getParam, navigate, showLiveChat, showMobileBetSlip],
  );

  const signInHandler = useCallback(() => {
    let timeout = 0;
    if (width < BREAKPOINT_XS && showMobileBetSlip) {
      timeout = 500;
      dispatch(setShowMobileBetSlip(false));
    }
    setTimeout(() => {
      dispatch(setAuthModal({tabIndex: 0, isVisible: true}));
      timeout = 0;
    }, timeout);
  }, [dispatch, showMobileBetSlip, width]);

  const registerHandler = useCallback(() => {
    let timeout = 0;
    if (width < BREAKPOINT_XS && showMobileBetSlip) {
      timeout = 500;
      dispatch(setShowMobileBetSlip(false));
    }
    setTimeout(() => {
      dispatch(setAuthModal({tabIndex: 1, isVisible: true}));
      timeout = 0;
    }, timeout);
  }, [dispatch, showMobileBetSlip, width]);

  return (
    <div
      className="fixed-bottom appMobileBottomTab column"
      style={{
        zIndex:
          showMobileBetSlip &&
          (location?.pathname === '/casino/poker' || !!casinoGameIdParam)
            ? 101
            : 17,
      }}>
      <LiveChat show={showLiveChat} />
      {!isLoggedIn && (
        <div className="flex items-center justify-center gap-10 py-sm signInButtonsContainer px-md">
          <div
            onClick={signInHandler}
            className="whiteBackground pa-md full-width items-center flex justify-center buttonItem">
            <span className="redText bold-700 text-body1">
              {t('signInLower')}
            </span>
          </div>
          <div
            onClick={registerHandler}
            className="whiteBackground pa-md full-width items-center flex justify-center buttonItem">
            <span className="blueText bold-700 text-body1">
              {t('registerLower')}
            </span>
          </div>
        </div>
      )}
      <div className="row items-center justify-between blueBackground">
        {(location?.pathname?.includes('/casino')
          ? MOBILE_BOTTOM_TABS_CASINO
          : MOBILE_BOTTOM_TABS_SPORTS
        )?.map(item => {
          const Icon =
            item?.icon === 'casino'
              ? CasinoIcon
              : item?.icon === 'explore'
              ? ExploreIcon
              : item?.icon === 'betSlip'
              ? BetSlipIcon
              : item?.icon === 'jackpot'
              ? JackpotIcon
              : item?.icon === 'poker'
              ? PokerIcon
              : item?.icon === 'slots'
              ? SlotsIcon
              : item?.icon === 'liveCasino'
              ? LiveCasinoIcon
              : LiveChatIcon;

          const isActive =
            (item?.path &&
              location.pathname?.includes(item?.path) &&
              !showMobileBetSlip &&
              !showLiveChat) ||
            (item?.icon === 'betSlip' && showMobileBetSlip) ||
            (item?.icon === 'liveChat' && showLiveChat);
          return (
            <div
              key={item?.id}
              onClick={e => {
                e.stopPropagation();
                tabPressHandler(item);
              }}
              className="item full-width pt-xs textCenter column items-center justify-center">
              <div
                className={`${
                  isActive && 'yellowBackground'
                } full-width activeBorders resetLineHeight`}>
                <div className="mt-xs">
                  <div className="relative inline-block">
                    <Icon
                      width={20}
                      height={20}
                      fill={isActive ? 'var(--appBlue)' : 'var(--white)'}
                    />
                    {item?.icon === 'betSlip' && !!betSlipCount && (
                      <div className="absolute items-center flex justify-center betSlipCounter">
                        <span className="text-caption-extra-small blueText bold-600 resetLineHeight">
                          {betSlipCount}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <span
                  className={`text-caption-small textWithDotes ${
                    isActive ? 'blueText' : 'whiteText'
                  }`}>
                  {t(item?.title)}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(MobileBottomTab);

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {getBetSlipCount} from '../../../Redux/AppSlice/AppSelectors';

import EventItem from './EventItem';

const EventItemContainer = ({
  gameId,
  marketId,
  eventItem,
  displayKey,
  isSuspended,
}) => {
  const betSlipCount = useSelector(getBetSlipCount);

  const [showOddChange, setShowOddChange] = useState(false);

  useEffect(() => {
    if (typeof eventItem?.lastPrice !== 'undefined') {
      setShowOddChange(true);
      setTimeout(() => setShowOddChange(false), 1500);
    }
  }, [eventItem?.lastPrice]);

  return (
    <div className="flex">
      <div className="columnCenter relative">
        <EventItem
          showBase
          type={4}
          showBoosted
          gameId={gameId}
          key={betSlipCount}
          marketId={marketId}
          base={eventItem?.base}
          eventId={eventItem?.id}
          displayKey={displayKey}
          price={eventItem?.price}
          isSuspended={isSuspended}
          showOddChange={showOddChange}
          lastPrice={eventItem?.lastPrice}
        />
      </div>
    </div>
  );
};

export default EventItemContainer;

import React, {useMemo} from 'react';

import EventItemContainer from './EventItemContainer';

const Events = ({gameItem, displayKey, isSuspended, marketsArray}) => {
  const eventsArray = useMemo(
    () => Array.apply(null, Array(marketsArray?.col_count)),
    [marketsArray?.col_count],
  );

  return eventsArray?.map((item, index) => {
    const eventItem = Object.values(marketsArray?.event || {})?.find(
      item => item?.order === index,
    );

    return (
      <EventItemContainer
        gameId={gameItem?.id}
        eventItem={eventItem}
        displayKey={displayKey}
        isSuspended={isSuspended}
        marketId={marketsArray?.id}
        key={!!eventItem ? eventItem?.id : gameItem?.id + index}
      />
    );
  });
};

// DO NOT PUT IN MEMO
export default Events;

import React, {memo, useCallback, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import './mobileHeader.scss';

import {useScreenSize} from '../../Hooks';

import {
  setShowBetSlip,
  setMainMenuModal,
  setShowSearchModal,
  setShowMobileBetSlip,
} from '../../Redux/AppSlice';
import {
  getIsLoggedIn,
  getShowBetSlip,
  getBetSlipCount,
  getShowMobileBetSlip,
  getIsContentScrolled,
} from '../../Redux/AppSlice/AppSelectors';
import {setHeaderTabIndex} from '../../Redux/BettingSlice';
import {getUser} from '../../Redux/UserSlice/UserSelectors';
import {setShowCasinoSearchModal} from '../../Redux/CasinoSlice';

import HeaderLogo from '../../Assets/Layout/HeaderLogo.png';
import {ReactComponent as UpArrow} from '../../Assets/Icons/Globals/topArrow.svg';
import {ReactComponent as BetSlipIcon} from '../../Assets/Icons/Globals/betslip.svg';
import {ReactComponent as DownArrow} from '../../Assets/Icons/Globals/downArrow.svg';
import {ReactComponent as SearchIcon} from '../../Assets/Icons/Globals/search.svg';
import {ReactComponent as OpenBetsIcon} from '../../Assets/Icons/Globals/myBets.svg';
import {ReactComponent as CrownIcon} from '../../Assets/Icons/Globals/crown.svg';

import {BREAKPOINT_XS} from '../../Constants/Globals';
import {FILTERS_TODAY_NAME} from '../../Constants/MatchFilters';

import {AppButton, AppLanguageChooser} from '../UI';
import DepositButton from './DepositButton';
import MenuDropDown from '../Home/MenuDropDown';
import SportFilters from '../Home/SportFilters';
import CasinoFixedGames from '../Casino/CasinoFixedGames';

const MobileHeader = ({
  leftIcon,
  showMenu = true,
  showSearch = true,
  showFilters = true,
  isCasinoGame = false,
  showLanguageChooser = true,
  showBetSlipToggleButton = false,
}) => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();

  const mainMenuDropDownContainerRef = useRef(null);

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const showBetSlip = useSelector(getShowBetSlip);
  const betSlipCount = useSelector(getBetSlipCount);
  const showMobileBetSip = useSelector(getShowMobileBetSlip);
  const isContentScrolled = useSelector(getIsContentScrolled);

  const [isMainMenuDropDownOpen, setIsMainMenuDropDownOpen] = useState(false);

  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );

  const onSearchClick = useCallback(
    e => {
      e.stopPropagation();
      dispatch(
        location?.pathname?.includes('casino')
          ? setShowCasinoSearchModal(true)
          : setShowSearchModal(true),
      );
    },
    [dispatch, location?.pathname],
  );

  const ArrowIcon = isMainMenuDropDownOpen ? UpArrow : DownArrow;

  return (
    <div className="flex column mobileHeaderContainerWrapper">
      <div
        className={`flex justify-between column mobileHeaderContainer items-center`}>
        <div className="flex justify-between row items-center full-width fixedHeight darkBlueBackground">
          {leftIcon || (
            <div
              className="flex px-md"
              onClick={() => {
                if (getParam.get('gameFilter') !== FILTERS_TODAY_NAME) {
                  navigate(`/sports`);
                }
              }}>
              <img src={HeaderLogo} height={35} />
            </div>
          )}
          {!isLoggedIn && !isCasinoGame && (
            <div className="full-height flex items-end px-md pb-sm">
              <div className="row items-center gap-5">
                <div
                  onClick={e => onSearchClick(e)}
                  className="blueBackground pa-sm flex items-center justify-center rounded-borders">
                  <SearchIcon width={15} height={15} fill="var(--white)" />
                </div>
                <AppLanguageChooser type={2} />
              </div>
            </div>
          )}
          {isLoggedIn && <DepositButton />}

          {(isLoggedIn || isCasinoGame) && (
            <div className="menuContainer flex row items-center gap-5 relative">
              <div ref={mainMenuDropDownContainerRef}>
                <div className="flex items-center gap-5 mainMenu justify-end px-md cursor-pointer">
                  {isLoggedIn &&
                    width > BREAKPOINT_XS &&
                    showLanguageChooser && <AppLanguageChooser type={2} />}
                  {showSearch && (
                    <div
                      onClick={e => onSearchClick(e)}
                      style={{
                        height: 30,
                        width: 30,
                        backgroundColor: '#003069',
                      }}
                      className="cursor-pointer  flex items-center justify-center rounded-borders">
                      <SearchIcon width={15} height={15} fill="var(--white)" />
                    </div>
                  )}
                  {showMenu && (
                    <div
                      className="row items-center gap-5"
                      onClick={() =>
                        width < BREAKPOINT_XS
                          ? dispatch(
                              setMainMenuModal({
                                isVisible: true,
                                // tabIndex: 2,
                                isDesktop: false,
                              }),
                            )
                          : setIsMainMenuDropDownOpen(prevState => !prevState)
                      }>
                      <div
                        style={{
                          boxShadow: `0px 0px 15px -2px ${
                            user?.is_verified
                              ? 'var(--appYellow)'
                              : 'var(--appRed)'
                          }`,
                        }}
                        className="row gap-5 firstNameContainer whiteBackground justify-center items-center">
                        <span className="bold-600 font-13 blueText text-caption-small">
                          {user?.first_name?.charAt(0)?.toUpperCase()}
                        </span>
                      </div>
                      <ArrowIcon width={8} height={8} fill="var(--white)" />
                    </div>
                  )}
                </div>
                {isMainMenuDropDownOpen && showMenu && (
                  <MenuDropDown
                    contentRef={mainMenuDropDownContainerRef}
                    setIsMainMenuDropDownOpen={setIsMainMenuDropDownOpen}
                  />
                )}
              </div>
              {showBetSlipToggleButton && width > BREAKPOINT_XS && (
                <AppButton
                  type={19}
                  width={104}
                  height={30}
                  active={showBetSlip}
                  className="row items-center justify-center mr-md"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(setShowBetSlip(!showBetSlip));
                  }}
                  title={
                    <div className="flex row gap-7 items-center justify-center">
                      <CrownIcon
                        fill={showBetSlip ? 'var(--appBlue)' : 'white'}
                      />
                      <span className={`font-12 bold-600`}>{t('myBets')}</span>
                    </div>
                  }
                />
              )}
            </div>
          )}
        </div>
        {/*{!isContentScrolled && (*/}
        {/*  <div*/}
        {/*    className={`depositContainerWrapper gap-5 full-width row items-center justify-between ${*/}
        {/*      isLoggedIn ? 'pa-sm' : ''*/}
        {/*    }`}>*/}
        {/*    <div className="row items-center flex-shrink">*/}
        {/*      /!*<AppDownloadDropDown />*!/*/}
        {/*      {isLoggedIn && (*/}
        {/*        <div*/}
        {/*          className="blueBackground row items-center gap-5 justify-center px-sm py-xs rounded-borders"*/}
        {/*          onClick={() => {*/}
        {/*            dispatch(setHeaderTabIndex(1));*/}
        {/*            dispatch(setShowMobileBetSlip(true));*/}
        {/*          }}>*/}
        {/*          <OpenBetsIcon width={13} fill="white" />*/}
        {/*          <span className="text-caption-extra-small whiteText bold-700">*/}
        {/*            {t('openBets')}*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      )}*/}
        {/*      /!*{location?.pathname?.includes('/casino') && (*!/*/}
        {/*      /!*  <div className="relative flex items-center cursor-pointer">*!/*/}
        {/*      /!*    {!!betSlipCount && (*!/*/}
        {/*      /!*      <div className="absolute items-center flex justify-center betSlipCounter">*!/*/}
        {/*      /!*        <span className="text-caption-extra-small blueText bold-600">*!/*/}
        {/*      /!*          {betSlipCount}*!/*/}
        {/*      /!*        </span>*!/*/}
        {/*      /!*      </div>*!/*/}
        {/*      /!*    )}*!/*/}
        {/*      /!*    <BetSlipIcon*!/*/}
        {/*      /!*      width={25}*!/*/}
        {/*      /!*      height={25}*!/*/}
        {/*      /!*      className="mx-md"*!/*/}
        {/*      /!*      fill="var(--appBlue)"*!/*/}
        {/*      /!*      onClick={() =>*!/*/}
        {/*      /!*        dispatch(setShowMobileBetSlip(!showMobileBetSip))*!/*/}
        {/*      /!*      }*!/*/}
        {/*      /!*    />*!/*/}
        {/*      /!*  </div>*!/*/}
        {/*      /!*)}*!/*/}
        {/*    </div>*/}
        {/*    {isLoggedIn && <DepositButton />}*/}
        {/*    {isLoggedIn && <AppLanguageChooser type={2} />}*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
      {/*{showFilters && (*/}
      {/*  <div*/}
      {/*    className={`filtersContainer ${*/}
      {/*      (location?.pathname?.includes('/casino/poker') ||*/}
      {/*        !!sportGameIdParam) &&*/}
      {/*      'hidden'*/}
      {/*    }`}>*/}
      {/*    /!*{location?.pathname?.includes('/casino') && (*!/*/}
      {/*    /!*  <CasinoFixedGames isMobile />*!/*/}
      {/*    /!*)}*!/*/}
      {/*    {!location?.pathname?.includes('/casino') &&*/}
      {/*      !location?.pathname?.includes('/jackpot') && (*/}
      {/*        <SportFilters isMobile />*/}
      {/*      )}*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default memo(MobileHeader);

import React, {memo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {
  getIsLoggedIn,
  getBetSlipCount,
} from '../../../../Redux/AppSlice/AppSelectors';
import {
  getOpenBetsCount,
  getHeaderTabIndex,
} from '../../../../Redux/BettingSlice/BettingSelectors';
import {setHeaderTabIndex} from '../../../../Redux/BettingSlice';

import {useScreenSize, useSocket} from '../../../../Hooks';

import {BREAKPOINT_XS} from '../../../../Constants/Globals';
import {BETSLIP_HEADER_TABS} from '../../../../Constants/Betting';

import Counter from '../Counter';
import {AppButton} from '../../../UI';
import LoadEventsContainer from '../LoadEventsContainer';

const HeaderTabs = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const {getOpenedBetsCount} = useSocket();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const betSlipCount = useSelector(getBetSlipCount);
  const openBetsCount = useSelector(getOpenBetsCount);
  const headerTabIndex = useSelector(getHeaderTabIndex);

  useEffect(() => {
    getOpenedBetsCount();
  }, []);

  useEffect(
    () => () => {
      dispatch(setHeaderTabIndex(0));
    },
    [],
  );

  return (
    <div
      className={`column gap-10 full-width pt-md ${
        width < BREAKPOINT_XS ? 'px-sm' : ''
      }`}>
      {isLoggedIn && headerTabIndex === 0 && <LoadEventsContainer />}
      <div className={`row items-center gap-5  full-width`}>
        {BETSLIP_HEADER_TABS?.map(({key, title, tabIndex}) => (
          <AppButton
            type={7}
            key={key}
            height={30}
            active={headerTabIndex === tabIndex}
            className="py-sm flex items-center justify-center"
            onClick={() => dispatch(setHeaderTabIndex(tabIndex))}
            title={
              <div className="row items-center justify-center gap-5">
                <span className={`font-12 bold-600 blueText text-capitalize`}>
                  {`${t(title)?.toLowerCase()} ${
                    tabIndex === 0 && !!betSlipCount ? `(${betSlipCount})` : ''
                  }`}
                </span>
                {tabIndex === 1 && !!openBetsCount && (
                  <div style={{position: 'absolute', right: 2, top: 2}}>
                    <Counter
                      type={3}
                      value={openBetsCount}
                      active={headerTabIndex === tabIndex}
                    />
                  </div>
                )}
              </div>
            }
          />
        ))}
      </div>
    </div>
  );
};

export default memo(HeaderTabs);

import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {useScreenSize} from '../../../Hooks';

import {AppButton} from '../../UI';

import {
  getIsLoggedIn,
  getShowBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';
import {setAuthModal} from '../../../Redux/AppSlice';
import {getShowCasinoSideBar} from '../../../Redux/CasinoSlice/CasinoSelectors';

import {
  BREAKPOINT_MD,
  BREAKPOINT_XS,
  BREAKPOINT_XXS,
} from '../../../Constants/Globals';
import {FUN, REAL} from '../../../Constants/Socket';

const GameItem = ({gameItem, clickHandler, isInModal, fullwidth, index}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const showBetSlip = useSelector(getShowBetSlip);
  const showCasinoSideBar = useSelector(getShowCasinoSideBar);

  const [isImageLoading, setIsImageLoading] = useState(false);
  const [calculatedWidth, setCalculatedWidth] = useState(null);
  const [playDemoContainerStyle, setPlayDemoContainerStyle] = useState({
    display: 'none',
  });

  const gameItemWidth = useMemo(
    () =>
      isInModal && width > BREAKPOINT_XS
        ? 'calc(100%  / 12)'
        : width > BREAKPOINT_MD && !showBetSlip && !showCasinoSideBar
        ? 'calc(100%  / 9)'
        : width > BREAKPOINT_MD && showBetSlip && showCasinoSideBar
        ? 'calc(100%  / 7)'
        : width > BREAKPOINT_MD && (showBetSlip || showCasinoSideBar)
        ? 'calc(100%  / 8)'
        : width < BREAKPOINT_XXS
        ? 'calc(100%  / 4)'
        : width > BREAKPOINT_XS && width <= 1300
        ? 'calc(100%  / 5)'
        : 'calc(100%  / 7)',
    [isInModal, showBetSlip, showCasinoSideBar, width],
  );

  const preloadImage = useCallback(url => {
    setIsImageLoading(true);
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setIsImageLoading(false);
    };
    img.onerror = () => {
      setIsImageLoading(false);
    };
  }, []);

  useEffect(() => {
    preloadImage(gameItem?.icon_3);

    const element = document.getElementById('gameItemId');
    const width = element.getBoundingClientRect().width;
    setCalculatedWidth(width);
  }, []);

  return (
    <div
      onClick={e => {
        if (width < BREAKPOINT_XS) {
          if (playDemoContainerStyle?.display === 'flex') {
            setPlayDemoContainerStyle({display: 'none'});
          } else {
            setPlayDemoContainerStyle({display: 'flex'});
          }
        }
        if (!!isLoggedIn) {
          clickHandler({mode: REAL});
        }
      }}
      onMouseEnter={e => {
        setPlayDemoContainerStyle({display: 'flex'});
      }}
      onMouseLeave={e => {
        setPlayDemoContainerStyle({display: 'none'});
      }}
      className="casinoGameItemContainer cursor-pointer"
      {...(index === 0 ? {id: 'gameItemId'} : {})}
      style={{
        width: fullwidth ? '100%' : gameItemWidth,
      }}>
      {isImageLoading ? (
        <div className="rowCenter full-width full-height loaderContainer">
          <span className="loader"></span>
        </div>
      ) : (
        <div style={{position: 'relative'}}>
          <img
            alt={gameItem?.name}
            src={gameItem?.icon_3}
            className="block image full-width"
          />
          <div
            style={{
              left: 0,
              right: 0,
              bottom: 10,
              position: 'absolute',
              ...playDemoContainerStyle,
            }}
            className={`flex row items-center px-md gap-5 ${
              calculatedWidth < 120 && 'wrap mb-xxs'
            }`}>
            {gameItem?.types?.realMode && !isLoggedIn && (
              <AppButton
                small
                type={3}
                title={t('play')}
                className="cursor-pointer"
                titleClassName="text-capitalize bold-600"
                onClick={() => {
                  clickHandler({mode: REAL});
                  if (!isLoggedIn) {
                    dispatch(setAuthModal({tabIndex: 0, isVisible: true}));
                  }
                }}
              />
            )}
            {gameItem?.types?.funMode && !isLoggedIn && (
              <AppButton
                small
                type={1}
                title={t('demo')}
                titleClassName="bold-600"
                className="cursor-pointer"
                onClick={() => clickHandler({mode: FUN})}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(GameItem);

import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {
  getBoostedGameIds,
  getActiveFilterParams,
} from '../../../Redux/SportSlice/SportSelectors';
import {getWssSocket} from '../../../Redux/AppSlice/AppSelectors';
import {setIsBetSlipDataLoading} from '../../../Redux/BettingSlice';

import {useScreenSize, useSocket, useStorage} from '../../../Hooks';

import {getPriceLabel} from '../../../Utils/GetOddValue';

import {
  UNSUBSCRIBE_BULK,
  UNSUBSCRIBE_LIVE_EVENT,
} from '../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {MARKETS_HANDICAP} from '../../../Constants/MatchFilters';

import {ReactComponent as Triangle} from '../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as BlockIcon} from '../../../Assets/Icons/Globals/blockedGame.svg';
import {ReactComponent as BoostedOddIcon} from '../../../Assets/Icons/Globals/BoostedIcon.svg';

const EventItem = ({
  base,
  price,
  gameId,
  eventId,
  betSlip,
  oldPrice,
  marketId,
  disabled,
  type = 1,
  isBlocked,
  lastPrice,
  displayKey,
  isSuspended,
  isLast = null,
  isFirst = null,
  showBase = false,
  showBoosted = false,
  featuredGame = false,
  showOddChange = true,
  eventItemContainerStyles = {},
}) => {
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();
  const {getBetSlipDataFromStorage} = useStorage();
  const {subscribeToEvent, unSubscribeLiveEvents} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const boostedIds = useSelector(getBoostedGameIds);
  const activeFilterParams = useSelector(getActiveFilterParams);

  const [selectedEventId, setSelectedEventId] = useState(
    getBetSlipDataFromStorage()?.find(item => item?.unique_id === eventId)
      ?.unique_id || null,
  );

  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );

  const isOddIncreased = useMemo(() => price > lastPrice, [lastPrice, price]);

  const isBoostedIconVisible = useMemo(
    () => boostedIds?.[gameId]?.[marketId]?.[eventId],
    [boostedIds, eventId, gameId, marketId],
  );

  const clickHandler = useCallback(
    e => {
      e.stopPropagation();
      setSelectedEventId(eventId);
      if (eventId === selectedEventId) {
        setSelectedEventId(null);
        unSubscribeLiveEvents({
          subId: getBetSlipDataFromStorage()?.find(
            item => item?.unique_id === eventId,
          )?.subId,
          rid: `${UNSUBSCRIBE_LIVE_EVENT}_${eventId}`,
        });
      } else {
        subscribeToEvent({eventId, gameId, marketId});
        setSelectedEventId(eventId);
        dispatch(setIsBetSlipDataLoading(true));
      }
    },
    [
      gameId,
      eventId,
      dispatch,
      marketId,
      selectedEventId,
      subscribeToEvent,
      unSubscribeLiveEvents,
      getBetSlipDataFromStorage,
    ],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      if (data?.rid?.includes(`${UNSUBSCRIBE_LIVE_EVENT}_`)) {
        const unsubscribedEventId = +data?.rid?.split?.('_')?.[1];
        if (unsubscribedEventId === eventId) {
          setSelectedEventId(null);
        }
      }
      if (data?.rid === `${UNSUBSCRIBE_BULK}_betSlip`) {
        setSelectedEventId(null);
      }
    },
    [eventId],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <>
      {showBase && !!base && (
        <div
          style={{bottom: 40, opacity: 0.5}}
          className={`flex gap-2 blueText bold-700 font-8 absolute`}>
          <span className={`${!base ? 'invisible' : ''}`}>
            {displayKey === MARKETS_HANDICAP && +base > 0 && '+'}
            {base || 'emptyText'}
          </span>
        </div>
      )}
      <div
        onClick={
          disabled || !price || typeof price === 'undefined' || isSuspended
            ? () => {}
            : clickHandler
        }
        className={`rowCenter relative boldText ${
          disabled ? '' : 'cursor-pointer'
        } eventItemContainer ${
          width < BREAKPOINT_XS && !betSlip
            ? `mobile ${
                isFirst ? 'borderRadiusFirst' : isLast ? 'borderRadiusLast' : ''
              } ${
                (typeof isFirst !== 'boolean' && typeof isLast !== 'boolean') ||
                (typeof price === 'undefined' && !isSuspended)
                  ? 'rounded-borders'
                  : ''
              }`
            : `desktop ${betSlip ? 'betslip' : ''} ${
                featuredGame ? '' : 'rounded-borders'
              } `
        } ${isBlocked ? 'blocked' : 'not-blocked'} color-type-${type} ${
          type === 4 &&
          typeof lastPrice !== 'undefined' &&
          !isSuspended &&
          !!price &&
          showOddChange
            ? isOddIncreased
              ? 'increased'
              : 'decreased'
            : ''
        } ${eventId === selectedEventId ? 'selected' : 'no-selected'} ${
          +sportGameIdParam === +gameId &&
          eventId === selectedEventId &&
          'selectedBorders'
        } ${showBoosted && isBoostedIconVisible ? 'boostedOdds gap-3' : ''} ${
          featuredGame
            ? `featuredGame ${
                isFirst ? 'borderRadiusFirst' : isLast ? 'borderRadiusLast' : ''
              } ${
                (typeof isFirst !== 'boolean' && typeof isLast !== 'boolean') ||
                (typeof price === 'undefined' && !isSuspended)
                  ? 'rounded-borders'
                  : ''
              }`
            : ''
        } ${(isSuspended || !price) && 'suspendedBackground'}`}
        style={eventItemContainerStyles}>
        {showBoosted && isBoostedIconVisible && (
          <BoostedOddIcon width={10} height={14} />
        )}
        {isSuspended || !price ? (
          <BlockIcon fill="var(--white)" width={15} height={15} />
        ) : (
          <span
            className={`${oldPrice ? 'text-strike' : ''} ${
              betSlip ? 'font-15 bold-700' : ''
            }`}>
            {!!price ? getPriceLabel(price) : '-'}
          </span>
        )}
        {typeof lastPrice !== 'undefined' &&
          !isSuspended &&
          !!price &&
          type !== 4 && (
            <Triangle
              width={15}
              height={15}
              className={`oddChangeIcon absolute ${
                !isOddIncreased ? 'increased' : 'decreased'
              }`}
              fill={isOddIncreased ? 'var(--lightGreen)' : 'var(--appRed)'}
            />
          )}
      </div>
    </>
  );
};

export default memo(EventItem);
